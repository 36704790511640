import React from 'react';
import { Disqus } from 'gatsby-plugin-disqus';
import MainLayout from '../../layouts/main';
import LocalImage from '../localImage';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import CodeBlock from '../codeBlock';
import { getKebabLink } from '../../utils/kebabCase';
import { Link } from 'gatsby';
import Youtube from '../widgets/youtube';
import Seo from '../seo';
import { ArticleWrapper, ArticleMetaWrapper } from './styled';

const ArticleMeta = ({ date, author, category, tags }) => {

  return (
    <ArticleMetaWrapper>
      <span className="meta">Published on {date} - by <Link to="/about">{author || 'Chanon'}</Link> </span><span className="meta"> Categorized in <Link to={getKebabLink(category, '/categories/')} className="category">{category}</Link></span>
      <span style={{ display: 'block' }} >
      { tags && tags.map( tag => (
        <Link to={getKebabLink(tag,'/tags/')} className="tag" key="{tag}">{tag}</Link>
      )) }
      </span>
    </ArticleMetaWrapper>
  );
}

const Article = ({ children, frontmatter, slug }) => {
  const { title, description, hero_image, author, date, category, tags } = frontmatter;
  const components = {
    Link,
    Youtube,
    LocalImage,
    pre: CodeBlock
  };

  const disqusConfig = {
    url: `https://chanon.info/${slug}`,
    title
  };

  return (
    <MainLayout>
      <Seo title={title} description={description} image={hero_image} article={true} />
      <ArticleWrapper>
        <h1 className="title">{title}</h1>
        <MDXProvider components={components} hello={title}>
            <ArticleMeta date={date} author={author} category={category} tags={tags} />
            <MDXRenderer 
              frontmatter={frontmatter}
            >
              {children}
            </MDXRenderer>
        </MDXProvider>
        <Disqus config={disqusConfig} />
      </ArticleWrapper>
    </MainLayout>
  );
};

export default Article;
